import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link, useLocation, withRouter, useHistory } from "react-router-dom";
import { Icon, Table, Form, Input, notification, Modal, Dropdown, Menu, Button } from "antd";
import axios from "axios";
import moment from "moment";

import ModalFicha from "../Modal/ModalFicha";
import ModalExportar from "../../../Modals/ModalExportar";
import ModalLoading from "../../../Modals/ModalLoading";
import DrawerNota from "../Drawer/DrawerNota";
import ModalViewer from "../../../Modals/ModalViewer";
import DrawerLogs from "../Drawer/DrawerLogs";
import DrawerDataLimiteNota from "../../../GerirDetalheTurma/Aproveitamento/Drawer/DrawerDataLimite";
import DrawerDataLimiteAvaliacao from "../../../GerirDetalheTurma/Aproveitamento/Drawer/DrawerDataLimiteAvaliacao";

import { GlobalContext } from "../../../GlobalState";
import {FaCalendarDay} from "react-icons/fa";

const { TextArea } = Input;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false
    };

    toggleEdit = () => {
        const { record } = this.props;

        if (record.permissao || record.permissaoAdm) {
            const editing = !this.state.editing;
            this.setState({ editing }, () => {
                if (editing) {
                    this.input.focus();
                }
            });
        }
    };

    saveInput = e => {
        const { record, handleSave } = this.props;
        let notaMin = record.notaMin;
        let notaMax = record.notaMax;

        if (notaMin === null || notaMin.trim() === "" || notaMax === null || notaMax.trim() === "") {
            this.form.validateFields((error, values) => {
                if (error && error[e.currentTarget.id]) {
                    return;
                }
                this.toggleEdit();
                handleSave({ ...record, ...values });
            });
            return;
        }

        notaMin = parseFloat(notaMin);
        notaMax = parseFloat(notaMax);

        if (isNaN(notaMin) || isNaN(notaMax)) {
            openNotificationWithIcon("error", "Erro", "As notas mínima e/ou máxima são inválidas.");
            return;
        }

        this.form.validateFields((error, values) => {
            let notaInserida = parseFloat(values.nota);

            if (error && error[e.currentTarget.id]) {
                return;
            }

            if (notaInserida < notaMin || notaInserida > notaMax) {
                openNotificationWithIcon("error", "Erro", `A nota inserida é inválida. A nota tem que estar entre ${notaMin} e ${notaMax}`);
                return;
            }

            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    savePicker = e => {
        const { record, handleSave } = this.props;

        handleSave({ ...record, data: moment(e).format("DD/MM/YYYY") });
        this.toggleEdit();
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;

        const { editing } = this.state;

        if (!editing)
            return (
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={this.toggleEdit}>
                    {children}
                </div>
            );

        return (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    initialValue: dataIndex === "data" ? moment(record[dataIndex], "DD/MM/YYYY") : record[dataIndex]
                })(
                    //dataIndex === "data" ?
                    //    <DatePicker
                    //        placeholder="Data Inicio"
                    //        format="DD/MM/YYYY"
                    //        ref={node => (this.input = node)}
                    //        open
                    //        onOpenChange={(status) => !status && this.savePicker()}
                    //        onChange={this.savePicker}
                    //    />
                    //    :
                    dataIndex === "nota" ? (
                        <Input ref={node => (this.input = node)} onPressEnter={this.saveInput} onBlur={this.saveInput} />
                    ) : (
                        <TextArea
                            ref={node => (this.input = node)}
                            onPressEnter={this.save}
                            onBlur={this.save}
                            rows={4}
                            style={{ height: 100 }}
                        />
                    )
                )}
            </Form.Item>
        );
    };

    render() {
        const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = this.props;
        return <td {...restProps}>{editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}</td>;
    }
}

const Detalhe = ({ match }) => {
    const context = useContext(GlobalContext);
    const history = useHistory();
    const location = useLocation();

    const entidadeId = localStorage.getItem('entidade_id');

    const [permissao, setPermissao] = useState([]);
    const [estado, setEstado] = useState("");
    const [texto, setTexto] = useState("");
    const [campoCalculado, setCampoCalculado] = useState(false);
    const [header, setHeader] = useState({
        epoca: "",
        pauta: ""
    });
    const [avaliacoes, setAvalicoes] = useState([]);
    const [replicarNota, setReplicarNota] = useState("");
    const [replicarConteudo, setReplicarConteudo] = useState("");
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");
    const [loadingText, setLoadingText] = useState("");
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [utilizadorId, setUtilizadorId] = useState(0);
    const [notaId, setNotaId] = useState(0);
    const [visible, setVisible] = useState(false);
    const [pautas, setPautas] = useState([]);
    const [idNota, setIdNota] = useState(0);
    const [idAvaliacao, setIdAvaliacao] = useState(0);

   
    //VIEWER
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [docs, setDocs] = useState([]);
    const [previousLocation, setPreviousLocation] = useState("");
    //DRAWER LOGS
    const [visibleDrawerLogs, setVisibleDrawerLogs] = useState(false);
    const [visibleDrawerAlterarDataNota, setVisibleDrawerAlterarDataNota] = useState(false)
    const [visibleDrawerAlterarDataAvaliacao, setVisibleDrawerAlterarDataAvaliacao] = useState(false)

    const acesso = context.acessos?.find(x => x.func === 21);
    const acessoApagarNota = context.acessos?.find(x => x.func === 25);
    const acessoApagarConteudo = context.acessos?.find(x => x.func === 25);
    const acessoAlterarData = context.acessos?.find(x => x.func === 27);

    const base64DecodeToLong = (code) =>  {

        var decodedString = atob(code);

        var decodedNumber = parseInt(decodedString, 10);

        return Number(decodedNumber);
    }

    const handleSave = row => {
        const newData = [...avaliacoes];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        setAvalicoes(newData);
        if (estado !== "") setEstado("");
    };

    const handleReplicarNota = e => {
        const value = e.target.value;
        setReplicarNota(value);
    };

    const handleReplicarConteudo = e => {
        const value = e.target.value;
        setReplicarConteudo(value)
    };
    
    const handleReplicar = () => {
        let mappedAvaliacoes = avaliacoes.map(registo => {
            return {
                ...registo,
                nota: replicarNota,
                guardadaBD: false
            }
        });

        setAvalicoes(mappedAvaliacoes);
    };

    const handleReplicarConteudoAvaliacao = () => {
        let mappedAvaliacoes = avaliacoes.map(registo => {
            return {
                ...registo,
                observacoes: replicarConteudo,
                guardadaBD: false,
            }
        });

        setAvalicoes(mappedAvaliacoes);
    };

    const guardar = publicar => {
        if (validarExistemNotas(avaliacoes)) {
            setReplicarNota("");
            setReplicarConteudo("");

            const temp = avaliacoes.map(avalicao => {
                return {
                    id: avalicao.key,
                    numero: avalicao.numero,
                    nome: avalicao.nome,
                    nota: avalicao.nota,
                    observacoes: avalicao.observacoes
                };
            });

            let item = new FormData();
            item.append("turmaId", localStorage.getItem("codigo_curso"));
            item.append("code", match.params.code);
            item.append("pauta", JSON.stringify(temp));
            item.append("publicar", publicar);

            axios({
                method: "put",
                url: "/api/turma/guardar-pauta",
                data: item
            })
                .then(response => {
                    openNotificationWithIcon("success", "Sucesso", publicar ? "Resultados guardados e publicados." : "Resultados guardados.");
                    info();
                    listar();
                })
                .catch(error => {
                    openNotificationWithIcon(
                        "error",
                        "Erro",
                        publicar ? "Não foi possível guardar e publicar os resultados." : "Não foi possível guardar os resultados."
                    );
                });
        }
        else {
            openNotificationWithIcon("error", "Erro", "Não foram adicionadas notas!");
        }
    };

    const validarExistemNotas = (avaliacoes) => {
        let temNotas = false;

        avaliacoes.forEach(avaliacao => {
            if (avaliacao.nota !== null && avaliacao.nota !== '' && avaliacao.nota !== undefined) {
                temNotas = true;
            }
        });

        return temNotas;
    }

    const guardarPublicar = publicar => {
        confirm({
            title: "Pretende guardar e publicar os resultados?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                guardar(publicar);
            }
        });
    };

    const publicar = () => {
        confirm({
            title: "Pretende publicar os resultados?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                let item = new FormData();
                item.append("disciplinaId", localStorage.getItem("codigo_curso"));
                item.append("code", match.params.code);

                axios({
                    method: "put",
                    url: "/api/turma/publicar-pauta",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", response.data);
                        info();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível publicar os resultados.");
                    });
            }
        });
    };

    const carregarCodigo = () => {
        axios({
            method: "get",
            url: "/api/turma/carregar-codigo",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                history.push(`${history.location.pathname.replace(`/${match.params.code}`, `/${response.data}`)}`);
            })
            .catch(error => {});
    };

    const info = () => {
        axios({
            method: "get",
            url: "/api/turma/info-pauta",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                if (response.data) {
                    if (response.data !== "CAMPO_CALCULADO") {
                        setEstado(response.data.estado);
                        setTexto(response.data.texto);
                        setCampoCalculado(response.data.campoCalculado);
                    }
                    else
                        setCampoCalculado(true);
                }
            })
            .catch(error => {});
    };

    const infoProf = () => {
        axios({
            method: "get",
            url: "/api/turma/info-permissao",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                organismoId: localStorage.getItem("organismo")
            }
        })
            .then(response => {
                setPermissao(response.data);
            })
            .catch(error => {});
    };

    const listar = () => {
        setLoading(true);
        axios({
            method: "get",
            url: "/api/turma/listar-avaliacao-notas",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                var registos = response.data.map(registo => {
                    const optionVisivel = registo.logs || acessoAlterarData != null;

                    return {
                        key: registo.id,
                        notaId: registo.notaId,
                        numero: registo.numero,
                        idAvaliacao :registo.idAvaliacao,
                        nome: registo.nome,
                        data: registo.data ? moment(registo.data).format("DD/MM/YYYY") : null,
                        nota: registo.nota,
                        notaMin: registo.notaMin,
                        notaMax: registo.notaMax,
                        observacoes: registo.observacoes,
                        permissao: registo.permissao,
                        permissaoAdm: registo.permissaoAdm,
                        logs: registo.logs,
                        guardadaBD: true,
                        opcoes: optionVisivel && (
                            <Dropdown overlay={montarMenu(registo.id, registo.notaId, registo.logs)} placement="bottomLeft">
                                <Link to="#" className="botao-icon-configs">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>
                        )
                    };
                });
                setAvalicoes(registos);
                setLoading(false);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }

                setLoading(false);
            });
    };

    const montarMenu = (utilizadorId, notaId, logs) => {
        return (
            <Menu>
                {logs && (
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                setNotaId(notaId);
                                setVisibleDrawerLogs(true);
                            }}
                        >
                            Logs
                        </Link>
                    </Menu.Item>
                )}
                {acessoAlterarData  && (
                    <Menu.Item>
                        <Link
                            to="#"
                            onClick={() => {
                                setIdNota(notaId);
                                setUtilizadorId(utilizadorId);
                                setVisibleDrawerAlterarDataNota(true);
                            }}
                        >
                            Alterar Data
                        </Link>
                    </Menu.Item>
                )}
                
            </Menu>
        );
    };

    const exportarPdf = () => {
        setVisibleLoading(true);
        setLoadingText("A gerar documento...");
        axios({
            method: "get",
            url: "/api/pdf/notas-exame",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true);
                setDocs([{ uri: response.data }]);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleLoading(false);
            });
    };

    const exportarExcel = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/excel/exportar",
            params: {
                turmaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    };

    const importarExcel = ficheiro => {
        setVisibleLoading(true);
        setLoadingText("A importar registos...");

        let item = new FormData();
        item.append("turmaId", localStorage.getItem("codigo_curso"));
        item.append("code", match.params.code);
        item.append("ficheiro", ficheiro);

        axios({
            method: "put",
            url: "/api/excel/importar",
            data: item
        })
            .then(() => {
                setVisibleLoading(false);
                listar();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                setVisibleLoading(false);
            });
    };

    const validarFormato = formato => {
        var formatos = ["xlsx"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    const uploadFoto = event => {
        if (validarFormato(event.target.files[0].name.split(".").pop().toLowerCase())) {
            importarExcel(event.target.files[0]);
            document.getElementById("upload-doc").value = "";
        } else {
            document.getElementById("upload-doc").value = "";
            openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
        }
    };

    const trocarPauta = tipo => {
        const index = pautas.findIndex(x => x.code === match.params.code);
        let avaliacao = null;
        if (tipo === "ANTERIOR") avaliacao = pautas[index - 1];
        else avaliacao = pautas[index + 1];

        localStorage.setItem(avaliacao.code, JSON.stringify({ epoca: avaliacao.epoca, grupo: avaliacao.grupo, pauta: avaliacao.pauta }));
        history.push(
            `/gerir-multisseriado/${match.params.turma.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/aproveitamento-notas/${
                avaliacao.code
            }`
        );
    };

    const carregarListaPautas = () => {
        axios({
            method: "get",
            url: "/api/turma/carregar-lista-pautas",
            params: {
                disciplinaId: localStorage.getItem("codigo_curso"),
                code: match.params.code
            }
        })
            .then(response => {
                setPautas(response.data);
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    };

    const apagarNota = notaId => {
        confirm({
            title: "Pretende apagar a nota?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aproveitamento/apagar-nota",
                    params: {
                        notaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Nota apagada.");

                        const registo = avaliacoes.find(x => x.notaId === notaId);
                        const updatedRecord = { ...registo, nota: null };
                        const updatedAvaliacoes = avaliacoes.map(registro => {
                            if (registro.key === updatedRecord.key) {
                                return updatedRecord;
                            }
                            return registro;
                        });
                        setAvalicoes(updatedAvaliacoes);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível apagar a nota.");
                    });
            }
        });
    };

    const apagarObservacao = notaId => {
        confirm({
            title: `Pretende apagar ${entidadeId == 33 ? "o conteúdo avaliativo" : "a observação"}?`,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/aproveitamento/apagar-observacoes",
                    params: {
                        notaId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", `${entidadeId == 33 ? "Conteúdo avaliativo apagado" : "Observação apagada"}.`);

                        const registo = avaliacoes.find(x => x.notaId === notaId)
                        const updatedRecord = { ...registo, observacoes: null };
                        const updatedAvaliacoes = avaliacoes.map(registro => {
                            if (registro.key === updatedRecord.key) {
                                return updatedRecord;
                            }
                            return registro;
                        });
                        setAvalicoes(updatedAvaliacoes);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", `Não foi possível apagar ${entidadeId == 33 ? "o conteúdo avaliativo" : "a observação"}.`);
                    });
            }
        });
    };
    
    const apagarCampoNaoGuardado = (notaId,campo, title) => {
        confirm({
            title: [title] ,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                const registo = avaliacoes.find(x => x.key === notaId)
                const updatedRecord = { ...registo, [campo]: null };
                const updatedAvaliacoes = avaliacoes.map(registro => {
                    if (registro.key === updatedRecord.key) {
                        return updatedRecord;
                    }
                    return registro;
                });
                
                setAvalicoes(updatedAvaliacoes);
            }
        });
    };

    useEffect(() => {
        if (location.pathname !== previousLocation.pathname) {
            carregarCodigo();
            info();
            infoProf();
            listar();

            if (!pautas.length)
                carregarListaPautas();

            if (localStorage.getItem(match.params.code)) {
                const info = JSON.parse(localStorage.getItem(match.params.code));
                setHeader(info);
            }
        }
        setPreviousLocation(location);
    }, [context.acessos, location.pathname]);

    const components = {
        body: {
            row: EditableFormRow,
            cell: EditableCell
        }
    };

    const editavel = acesso?.update;

    const columns2 = [
        {
            title: "Nº",
            dataIndex: "numero"
        },
        {
            title: "Nome",
            dataIndex: "nome"
        },
        {
            title: "Nota",
            dataIndex: "nota",
            editable: editavel,
            render: (text, record) => (
                <span className="grupo-inserir-nota">
                    <span className={`campo-inserir-nota ${!(record.permissao || record.permissaoAdm) ? "campo-inserir-nota-bloqueado" : ""}`}>
                        <strong>{text}</strong>
                    </span>
                    {acessoApagarNota && record.nota && editavel && (record.permissao || record.permissaoAdm) && (
                        <Link
                            to="#"
                            className="botao-icon-inativar"
                            onClick={event => {
                                event.stopPropagation();
                                if (!record.guardadaBD) {
                                    apagarCampoNaoGuardado(record.key, 'nota', "Pretende apagar a nota?" )
                                }
                                else {
                                    apagarNota(record.notaId);
                                }
                            }}
                        >
                            <Icon type="close" />
                        </Link>
                    )}
                </span>
            )
        },
        {
            title: entidadeId == 33 ? "Conteúdo avaliativo" : "Observações",
            dataIndex: "observacoes",
            editable: editavel,
            render: (text, record) => (
                <span className="grupo-inserir-nota">
                    <span className={`campo-inserir-nota ${!(record.permissao || record.permissaoAdm) ? "campo-inserir-nota-bloqueado" : ""}`}>
                        <strong>{text ? (text.length > 20 ? `${text.substring(0, 20)}...` : text) : ""}</strong>
                     </span>
                    {acessoApagarConteudo && record.observacoes && editavel && (record.permissao || record.permissaoAdm) && (
                        <Link
                            to="#"
                            className="botao-icon-inativar"
                            onClick={event => {
                                event.stopPropagation();
                                if (!record.guardadaBD) {
                                    apagarCampoNaoGuardado(record.key, 'observacoes', `Pretende apagar ${entidadeId == 33 ? "o conteúdo avaliativo" : "a observação"}?`)
                                } else {
                                    apagarObservacao(record.notaId);
                                }
                            }}
                        >
                            <Icon type="close"/>
                        </Link>
                    )}
                </span>

            )
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const columns = columns2.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave
            })
        };
    });

    if (redirect) return <Redirect to={redirectLink} />;

    return (
        <div className="container container-body">
            <div className="turma-resultados">
                <div className="controlos controlos-fundo" style={{ justifyContent: "flex-end" }}>
                    <div className="bloco-direita">
                        <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar PDF"
                              onClick={exportarPdf}>
                            <Icon type="eye"/>
                        </Link>
                        {!campoCalculado && acesso?.update && (
                            <Link
                                className={`botao-icon-print botao-maior ${
                                    estado === "PUBLICADO" || estado === "VALIDADO" ? "botao-disabled" : ""
                                }`}
                                disabled={estado === "VALIDADO"}
                                to="#"
                                title="Importar"
                                onClick={() => document.getElementById("upload-doc").click()}
                            >
                                <Icon type="upload"/>
                            </Link>
                        )}
                        <Link className="botao-icon-download botao-maior" to="#" title="Exportar"
                              onClick={exportarExcel}>
                            <Icon type="download"/>
                        </Link>
                        {acessoAlterarData && (
                            <button className="botao-icon-calendario" onClick={() => {
                                setVisibleDrawerAlterarDataAvaliacao(true);
                            }}>

                                <FaCalendarDay style={{ color: "white" }} />
                            </button>)}
                        <Link
                            to={`/gerir-multisseriado/${match.params.turma
                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                .toLowerCase()}/aproveitamento`}
                            className="botao-secundario botao-secundario-redondo"
                            style={{marginRight: 10, display: "flex", justifyContent: "center", alignItems: "center"}}
                        >
                            <Icon type="arrow-left"/>
                        </Link>
                    </div>
                </div>
                <div className="bloco">
                    <div className="info">
                        <div className="info-header-conceitos">
                            <div style={{fontSize: 20}}>
                                {header?.epoca} - {header?.grupo}
                            </div>
                            <div className="titulo-item">
                            {(pautas.findIndex(x => x.code === match.params.code) > 0 || !pautas.length) && (
                                    <Link className="botao-icon-clip" to="#" title="Anterior" onClick={() => trocarPauta("ANTERIOR")}>
                                        <Icon type="left" />
                                    </Link>
                                )}
                                <p>{header?.pauta}</p>
                                {(pautas.findIndex(x => x.code === match.params.code) < pautas.length - 1 || !pautas.length) && (
                                    <Link className="botao-icon-clip" to="#" title="Proximo" onClick={() => trocarPauta("PROXIMO")}>
                                        <Icon type="right" />
                                    </Link>
                                )}
                            </div>
                            <p className="estado" dangerouslySetInnerHTML={{ __html: texto }}></p>
                        </div>
                    </div>
                    {loading ? (
                        <></>
                    ) : (
                        <div>
                            <div style={{ display: "flex", alignItems: "center", gap: 10, marginBottom: 10 }}>
                                    <Input
                                        value={replicarNota}
                                        onChange={e => handleReplicarNota(e)}
                                        style={{ width: "135px" }}
                                    />
                                <Button
                                    disabled={isNaN(replicarNota) || replicarNota === "" || replicarNota < 0 || replicarNota > 10}
                                    className="botao-principal"
                                    style={{ fontSize: 12, border: "none", color: "white" }}
                                    onClick={e => handleReplicar(e)}
                                >
                                    Replicar Nota
                                </Button>
                            </div>
        
                            <div style={{ display: "flex", alignItems: "center", gap: 10, marginBottom: 10 }}>
                                    <Input
                                        value={replicarConteudo}
                                        onChange={e => handleReplicarConteudo(e)}
                                        style={{ width: "135px" }}
                                    />
                                <Button
                                    disabled={replicarConteudo === ""}
                                    className="botao-principal"
                                    style={{ fontSize: 12, border: "none", color: "white" }}
                                    onClick={e => handleReplicarConteudoAvaliacao(e)}
                                >
                                        {entidadeId === "33" ? "Replicar Conteúdo avaliativo" : "Replicar Observações"}
                                </Button>
                            </div>
                        </div>
                    )}
                    <Table
                        id="disciplinas"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        rowClassName={() => "editable-row"}
                        components={components}
                        columns={columns}
                        dataSource={avaliacoes}
                        pagination={false}
                        locale={{ emptyText: "Não existem dados!" }}
                    />
                    <div className="bloco-controls">
                        <Link className="botao-principal" to="#" onClick={() => guardar(false)}>
                            Guardar
                        </Link>
                    </div>
                </div>
            </div>
            <ModalFicha
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
                atualizar={() => {
                    setVisibleModal(false);
                    info();
                }}
            />
            <ModalExportar
                visibleExportar={visibleExport}
                btnDescarregar={buttonDownload}
                ficheiroExportar={exportFile}
                onCancel={() => setVisibleExport(false)}
            />
            <ModalLoading visible={visibleLoading} text={loadingText} />
            <input
                type="file"
                id="upload-doc"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={uploadFoto}
                style={{ display: "none" }}
            />
            <DrawerNota
                notaId={notaId}
                visible={visible}
                onClose={() => setVisible(false)}
                atualizar={() => {
                    setVisible(false);
                    listar();
                }}
            />
            <DrawerLogs
                notaId={notaId}
                visible={visibleDrawerLogs}
                onClose={() => setVisibleDrawerLogs(false)}
            />
            <DrawerDataLimiteNota
                Id={idNota}
                utilizadorId={utilizadorId}
                avaliacaoId={base64DecodeToLong(match.params.code)}
                visible={visibleDrawerAlterarDataNota}
                onClose={() => setVisibleDrawerAlterarDataNota(false)}
            />
            <DrawerDataLimiteAvaliacao
                Id={base64DecodeToLong(match.params.code)}
                code={match.params.code}
                visible={visibleDrawerAlterarDataAvaliacao}
                onClose={() => setVisibleDrawerAlterarDataAvaliacao(false)}
            />
            <ModalViewer
                visible={visibleViewer}
                docs={docs}
                onCancel={() => setVisibleViewer(false)}
            />
        </div>
    );
};

export default withRouter(Detalhe);
