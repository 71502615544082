import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import {Button, Icon, Modal, notification, Table} from "antd";
import UserInfo from "../../UserInfo/UserInfo";
import Tabela from "./Tabela";
import ModalLoading from "../../../../Modals/ModalLoading";
import ModalViewer from "../../../../Modals/ModalViewer";
import { GlobalContext } from "../../../../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Educacao = ({ menuOptionSelected, match }) => {
    const globalContext = useContext(GlobalContext);
    const [search, setSearch] = useState("");
    const [activated, setActivated] = useState(true);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [visibleViewer, setVisibleViewer] = useState(false);
    const [docs, setDocs] = useState([]);
    const [aExpirar, setAExpirar] = useState([])
    const [mostrarModal, setMostrarModal] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);

    
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    
    const handleGuardarExpirados = (valor) =>{
        setAExpirar(valor);
    }

   

    const gerarDoc = () => {
        setVisibleLoading(true);
        axios({
            method: "get",
            url: "/api/pdf/dados-gerais-professor",
            params: {
                edicaoId: globalContext[`edicao${match.params.code}`]?.id,
                escolaId: globalContext[`escola${match.params.code}`]?.id
            }
        })
            .then(response => {
                setVisibleLoading(false);
                setVisibleViewer(true);
                setDocs([{ uri: response.data }]);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível gerar documento!");
                setVisibleLoading(false);
            });
    };

    useEffect(() => {
        setSearch("");
        setIsModalOpen(true);
        const mostrarModal2 = localStorage.getItem("mostrarModal") === "true";
        setMostrarModal(mostrarModal2);
        localStorage.setItem("mostrarModal", false);
    }, []);

    const handleChangeSearch = value => {
        setSearch(value);
    };

    const resetSearch = () => {
        setActivated(true);
        setSearch("");
    };

    const getOrderClass = (ordem) => {
        if (ordem === 2) {
            return "ordem-1"; 
        } else if (ordem === 1) {
            return "ordem-2"; 
        } else if (ordem === 0) {
            return "ordem-3"; 
        } else {
            return "ordem-default"; 
        }
    };

    return (
        <>
            <div className="page-container">
                <UserInfo
                    educacaoProfessor
                    search={search}
                    handleChangeSearch={handleChangeSearch}
                    resetSearch={resetSearch}
                    setActivated={setActivated}
                />
                <div className="user-info-detail">
                    <div className="page-info">
                        <div>
                            <h1 className="page-title" style={{ margin: "20px 20px 10px 20px" }}>
                                Turmas
                            </h1>
                        </div>
                        <span className="page-controls">
                            <Link className="botao-icon-detalhe botao-maior" to="#" title="Descarregar" onClick={gerarDoc}>
                                <Icon type="eye" />
                            </Link>
                        </span>
                    </div>
                    <div className="block-table">
                        <Tabela search={search} activated={activated} setActivated={setActivated} handleGuardarExpirados = {handleGuardarExpirados}/>
                    </div>
                    
                </div>
                
                <ModalLoading visible={visibleLoading} text="A gerar documento..." />
                <ModalViewer visible={visibleViewer} docs={docs} onCancel={() => setVisibleViewer(false)} />
                
                {(aExpirar.length > 0 & mostrarModal & (localStorage.getItem("entidade_id") === '33')) && (
                    <Modal
                        title="Expirar período de inserção de notas"
                        visible={isModalOpen}
                        onOk={handleOk}
                        width={1000}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        {
                            aExpirar
                                .filter(x => x.periodoFinalizado != null)
                                .sort((a, b) => a.ordem - b.ordem)
                                .map((turma, index) => {
                                    return (
                                        <div key={index} className={`modal-linha ${getOrderClass(turma.ordem)}`}>
                                            <span>{turma.edicao}</span>
                                            <span>{turma.turma}</span>
                                            <span>{turma.periodoFinalizado}</span>
                                        </div>
                                    );
                                })
                        }
                    </Modal>
                )}
            </div>
        </>
    );
};

export default withRouter(Educacao);
